<template>
  <section class="blogs">
    <!-- <Loader :loading="loading" /> -->
    <template v-if="!loading">
      <div class="blogs__header" v-if="lastBlog.id ? true : false">
        <img class="blogs__header-image" :src="lastBlog.image" alt="blog image" />
        <div class="blogs__header-content">
          <p class="blogs__tag blogs__tag_header text-base">Blog</p>
          <h2 class="blogs__header-title text-title">{{ lastBlog.title }}</h2>
          <div class="blogs__info">
            <p class="blogs__info-author text-description">by {{ lastBlog.author }}</p>
            <p class="blogs__info-date text-description">
              {{ $moment(lastBlog.publish_date).format("MMM D, YYYY") }}
            </p>
          </div>
          <p class="blogs__header-description text-description" v-text="lastBlog.content"></p>
          <BaseButton
            class="blogs__button blogs__header-button button--lg button--white button--ellipse"
            text="Read More"
            @click="readMore('lastBlog')"
          />
        </div>
      </div>
      <Subscribe text="updates" />
      <div class="blogs__inner">
        <div
          class="blogs__item"
          v-for="(blog, index) in blogs"
          :key="`blog-${index}`"
          tabindex="0"
          @click="readMore(index)"
          @keyup.enter="readMore(index)"
        >
          <img class="blogs__item-image" :src="blog.preview_image" alt="blog image" />
          <div class="blogs__item-content">
            <div class="blogs__info">
              <p class="blogs__tag blogs__tag_margin text-base">Blog</p>
              <p class="blogs__info-date text-description">
                {{ $moment(blog.publish_date).format("MMM D, YYYY") }}
              </p>
            </div>
            <h2 class="blogs__item-title text-title">{{ blog.title }}</h2>
            <p class="blogs__item-description text-description" v-text="blog.content"></p>
          </div>
        </div>
      </div>
      <InfiniteLoading class="infinite-loading" @infinite="loadMore" />
      <div class="blogs__buttons-box">
        <BaseButton
          class="blogs__button blogs__button_mobile button--white button--lg button--ellipse"
          text="Load More"
          @click="loadMore"
        />
        <BaseButton
          class="blogs__button blogs__button_mobile button--outline-white button--lg button--ellipse"
          text="Back to Top"
          @click="backToTop"
        />
      </div>
    </template>
  </section>
</template>

<script>
import { scroller } from "vue-scrollto/src/scrollTo";
import { mapGetters, mapMutations, mapActions } from "vuex";
import Subscribe from "@/components/Subscribe.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import Loader from "@/components/Loader.vue";
import InfiniteLoading from "vue-infinite-loading";

export default {
  components: {
    Subscribe,
    BaseButton,
    Loader,
    InfiniteLoading,
  },
  data() {
    return {
      loading: false,
      busy: false,
      page: 1,
    };
  },
  head: {
    title: function () {
      return {
        inner: "GH - Blogs",
        separator: " ",
        complement: " ",
      };
    },
    meta: function () {
      return [];
    },
  },
  computed: {
    ...mapGetters("publicBlogs", {
      blogs: "getBlogsList",
      lastBlog: "getLastBlog",
      hasMorePages: "getHasMorePages",
    }),
  },
  async created() {
    this.loading = true;
    await this.apiGetBlogsList({ page: this.page });

    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Library",
        to: "/library",
      },
      {
        title: "Blog",
        to: "/library/blog",
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
    this.loading = false;
  },
  methods: {
    ...mapMutations(["setBreadcrumbs"]),
    ...mapActions("publicBlogs", ["apiGetBlogsList"]),
    async loadMore($state) {
      this.busy = true;
      if (this.hasMorePages) {
        this.page += 1;
        await this.apiGetBlogsList({ page: this.page, lastBlogId: this.lastBlog.id });
        $state.loaded();
        this.busy = false;
      } else {
        $state.complete();
      }
    },
    readMore(index) {
      if (index === "lastBlog") {
        this.$router.push({ path: `/library/blog/${this.lastBlog.slug}` });
      } else {
        this.$router.push({ path: `/library/blog/${this.blogs[index].slug}` });
      }
    },
    backToTop() {
      const firstScrollTo = scroller();
      firstScrollTo(".header_breadcrumbs");
    },
  },
};
</script>

<style lang="scss" scoped>
.blogs {
  min-width: 100vw;
  min-height: 100vh;

  &__header {
    position: relative;
    width: 100vw;
    height: 80vh;
    &-image {
      object-fit: cover;
      position: absolute;
      height: 100%;
      width: 100%;
      left: 50%;
      transform: translate(-50%, 0);
      @media (max-width: $xxs) {
        width: auto;
        max-width: none;
      }
    }
    &-content {
      position: absolute;
      height: 100%;
      width: 100%;
      background: linear-gradient(0deg, rgba(17, 17, 17, 1) 0%, rgba(0, 0, 0, 0) 100%);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 0 9rem 6rem 16.4rem;
      @media (max-width: $xxs) {
        padding: 0 25px 6rem 25px;
      }
    }
    &-title {
      @media (max-width: $xxs) {
        font-size: 3.7rem;
        line-height: 1.2;
      }
    }
    &-button {
      margin-top: 35px;
    }
    &-description {
      font-size: 2.1rem;
      max-width: 99.7rem;
      @media (max-width: $xxs) {
        font-size: 1.8rem;
        line-height: 1.55;
      }
    }
  }
  &__info {
    display: flex;
    align-items: center;
    &-author {
      margin-right: 15px;
      font-weight: 600;
      line-height: 2.44;
    }
    &-date {
      font-weight: 600;
      line-height: 2.44;
    }
  }
  &__tag {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.5;
    padding: 0 25px;
    height: 4rem;
    width: max-content;
    border-radius: 34px;
    border: 1px solid $white;
    min-width: 11rem;
    &_margin {
      margin-right: 3rem;
    }
    &_header {
      font-size: 1.7rem;
      height: 4.5rem;
      min-width: 12.6rem;
      margin-bottom: 4rem;
    }
  }
  &__inner {
    padding-right: 5px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 33.333vw;
    @media (max-width: $md) {
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: 50vw;
    }
    @media (max-width: $xxs) {
      grid-template-columns: 1fr;
      grid-auto-rows: 100vw;
    }
  }
  &__item {
    cursor: pointer;
    position: relative;
    border: 1px solid $white;
    border-bottom: none;
    &:nth-of-type(-n + 3) {
      border-top: none;
    }
    @media (max-width: $md) {
      &:nth-of-type(n) {
        border-top: 1px solid $white;
      }
      &:nth-of-type(-n + 2) {
        border-top: none;
      }
      border: 1px solid $white;
      border-bottom: none;
    }
    @media (max-width: $xxs) {
      &:nth-of-type(n) {
        border-top: 1px solid $white;
      }
      &:nth-of-type(-n + 1) {
        border-top: none;
      }
      border-left: none;
      border-right: none;
    }
    &-image {
      position: absolute;
      height: 100%;
      width: 100%;
    }
    &-content {
      position: absolute;
      height: 100%;
      width: 100%;
      background: linear-gradient(0deg, rgba(17, 17, 17, 1) 0%, rgba(0, 0, 0, 0) 100%);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 5rem 5rem 2.6rem 4rem;
      transition: all 0.5s ease;
      @media (max-width: $xxs) {
        padding: 20px 25px 10px;
      }
      &:hover {
        background-color: rgba($color: $black, $alpha: 0.83);
        .blogs__item-description {
          max-height: 48rem;
        }
      }
    }
    &-title {
      font-size: 3.9rem;
      line-height: 1.2;
      margin: 20px 0;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      line-clamp: 3;
      overflow: hidden;
      @media (max-width: $xxs) {
        font-size: 3.7rem;
      }
    }
    &-description {
      position: relative;
      transition: all 0.7s ease;
      overflow: hidden;
      height: 45%;
      max-height: 0;
    }
  }
  &__buttons-box {
    display: none;
    flex-direction: column;
    align-items: center;
    margin: 5rem 0rem 7rem;
    width: 100%;
    @media only screen and (max-width: $xxs) {
      display: flex;
    }
  }
  &__button {
    width: max-content;
    min-width: 18rem;
    &_mobile {
      min-width: 19rem;
      &:last-of-type {
        margin-top: 15px;
      }
    }
  }
}
</style>
